<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tbData"
      :single-expand="singleExpand"
      :search="search"
      :loading="loadingTb"
      loading-text="Sedang Memuat Data..."
      disable-pagination
      hide-default-footer
      :expanded.sync="expanded"
      item-key="id"
    >

      <template v-slot:item.noRow="{ item }">
        {{tbData.map(function(x) {return x.registerId; }).indexOf(item.registerId)+1}}
      </template>
      <template v-slot:item.tanggalAgreement="{ item }">
        <span v-if="item.tanggalAgreement">{{G_formatDate(item.tanggalAgreement,'d-m-Y') }}</span>
      </template>
      <template v-slot:item.tanggalEfektif="{ item }">
        <span v-if="item.tanggalEfektif">{{G_formatDate(item.tanggalEfektif,'d-m-Y') }}</span>
      </template>
      <template v-slot:item.tanggalProjectCompletion="{ item }">
        <span v-if="item.tanggalProjectCompletion">{{G_formatDate(item.tanggalProjectCompletion,'d-m-Y') }}</span>
      </template>
      <template v-slot:item.tanggalLoanClosing="{ item }">
        <span v-if="item.tanggalLoanClosing">{{G_formatDate(item.tanggalLoanClosing,'d-m-Y') }}</span>
      </template>
      <template v-slot:item.realisasiKumulatifSebelum="{ item }">
        {{G_numFormat(item.realisasiKumulatifSebelum)}}
      </template>
      <template v-slot:item.nilaiPjmRph="{ item }">
        {{G_numFormat(item.nilaiPjmRph)}}
      </template>

      <!-- <template v-slot:item.counterOs="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.counterOs)}}
                </div>
            </template>
               <template v-slot:item.clOs="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.clOs)}}
                </div>
            </template>
             <template v-slot:item.selisih="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.selisih)}}
                </div>
            </template> -->

      <template v-slot:item.actions="{ item }">
        <v-menu
          transition="slide-x-transition"
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#fafafa"
              class="btn-table"
              fab
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list
            flat
            dense
            elevation="1"
          >
            <v-list-item-group color="primary">
              <v-list-item @click="edit(item.registerId)">
                <v-list-item-icon class="mr-3">
                  <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group color="primary">
              <v-list-item @click="hapus(item.registerId)">
                <v-list-item-icon class="mr-3">
                  <v-icon small>mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Hapus</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

  </div>
</template>
<script>
export default {
  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        { text: "No", value: "noRow", sortable: false, width: "1%" },
        { text: "ID Register", value: "registerId" },
        { text: "Nama Register", value: "registerNm" },
        { text: "Projek", value: "projekId" },
        { text: "Lender", value: "lender" },
        { text: "PLN", value: "isPln" },
        { text: "SBSN", value: "isSbsn" },
        { text: "RM", value: "isRm" },
        { text: "Tanggal Agreement", value: "tanggalAgreement" },
        { text: "Tanggal Efektif", value: "tanggalEfektif" },
        {
          text: "Tanggal Project Completion",
          value: "tanggalProjectCompletion",
        },
        {
          text: "Tanggal Loan Closing",
          value: "tanggalLoanClosing",
        },
        {
          text: "Realisasi Kumulatif 2020",
          value: "realisasiKumulatifSebelum",
        },
        {
          text: "Nilai Pinjaman (Rp)",
          value: "nilaiPjmRph",
        },
        { text: "Act", value: "actions", sortable: false, width: "2%" },
      ],
    };
  },
  mounted() {},
  methods: {
    getTable() {
      this.$emit("getTable");
    },
    edit(id) {
      this.$emit("edit", id);
    },
    hapus(id) {
      this.$emit("hapus", id);
    },
  },

  props: ["tbData", "search", "loadingTb"],
};
</script>