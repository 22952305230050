import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listRegister: [],
      isShow: false,
      timeout:null,
      tableDetail: [],
      setDefault: null,
      dokumenId:null,
      loadingTbDetail:false,
      tbDataTags:[],
      currentDokumen:null,
      listTag: [],
      listFolder: [
        {
          text: 'Home',
          disabled: true,
          link: '/',
        }
       
      ],
      uploadRule:[
        v => !!v || 'Tidak Boleh Kosong'
      ]
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable();
    this.G_GetRef('register').then(data => {
      this.listRegister = data;
    });
    this.G_GetAny("dok/tags").then((res) => {
      let listData = [];
      let arrSub = {};
      let resData = res.data;

      for (let i = 0; i < resData.length; i++) {
        arrSub = {};
        arrSub["text"] = resData[i];
        arrSub["value"] = resData[i];
        listData.push(arrSub);
      }

      this.listTag = listData;
    });

    
  },
  components: {
    myTable,
  },
  methods: {
  changeInput() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.getTable, 1000)
      this.listFolder.length = 1;
      this.currentDokumen = null;   
  },
  kembali(){
    console.log(this.listFolder.length);
    let getLength = this.listFolder.length;

    let getFolder = this.listFolder[getLength-2]

    if (getFolder.link == '/') {
      this.currentDokumen = null;
      this.getTable()
    }else{
      this.openFolderBack(getFolder.link)
     
    }

    console.log(this.listFolder);
    this.listFolder.splice((getLength-1), 1);
    console.log('after',this.listFolder.length);
    
  },
  openFolderBack(dokumen){
    this.loadingTb = true;
    this.currentDokumen = dokumen
    this.G_GetAny("dok/search?dokumenIdParent="+dokumen).then((res) => {
      this.loadingTb = false;
      this.tbData = res.data;
    });
  },
  openFolder(dokumen,namaFolder){
    this.loadingTb = true;
    this.currentDokumen = dokumen
    this.G_GetAny("dok/search?dokumenIdParent="+dokumen).then((res) => {
      this.loadingTb = false;
      this.tbData = res.data;
      this.listFolder.push({
        text: namaFolder,
        disabled: false,
        link: dokumen,
      });
    });
  },
    getTable() {
      this.isShow = true;
      this.loadingTb = true;
      let filter = "";

      console.log(this.currentDokumen);

      if (this.currentDokumen) {
        this.G_GetAny("dok/search?dokumenIdParent="+this.currentDokumen).then((res) => {
          this.loadingTb = false;
          this.tbData = res.data;
        });
      }else{
        if (this.search) {
          filter = "?q="+this.search;
        }
        this.G_GetAny("dok/search"+filter).then((res) => {
          this.loadingTb = false;
          this.tbData = res.data;
        });
      }
      
    },

    edit(id) {
      console.log(id);
      this.$refs.modal.open("Edit Data Register", 600);
      this.setId = id;

      this.G_GetAny("register/" + id).then((res) => {
        this.getData = res.data;

        this.$nextTick(() => {
          this.$refs.projekId.setValue(res.data.projekId);
          this.$refs.isPln.setValue(res.data.isPln);
          this.$refs.isSbsn.setValue(res.data.isSbsn);
          this.$refs.isRm.setValue(res.data.isRm);

          if (res.data.tanggalAgreement) {
            this.tanggalAgreement = res.data.tanggalAgreement;
          } else {
            this.tanggalAgreement = "";
          }

          if (res.data.tanggalEfektif) {
            this.tanggalEfektif = res.data.tanggalEfektif;
          } else {
            this.tanggalEfektif = "";
          }

          if (res.data.tanggalProjectCompletion) {
            this.tanggalProjectCompletion = res.data.tanggalProjectCompletion;
          } else {
            this.tanggalProjectCompletion = "";
          }

          if (res.data.tanggalLoanClosing) {
            this.tanggalLoanClosing = res.data.tanggalLoanClosing;
          } else {
            this.tanggalLoanClosing = "";
          }

          if (res.data.realisasiKumulatifSebelum) {
            this.realisasiKumulatifSebelum = res.data.realisasiKumulatifSebelum;
          } else {
            this.realisasiKumulatifSebelum = "";
          }

          if (res.data.nilaiPjmRph) {
            this.nilaiPjmRph = res.data.nilaiPjmRph;
          } else {
            this.nilaiPjmRph = "";
          }
        });
      });
    },
    createFolder() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.setId = null;
      this.getData = [];
      this.$refs.modal.open("Buat Folder", 600);
    },
    addSubFolder(id){
     
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.setId = null;
      this.dokumenId = id;
      console.log(this.dokumenId);
      this.$refs.modal.open("Buat Sub Folder", 600);
    },
    storeFolder() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);

      if (this.currentDokumen) {
          formData.append("dokumenIdParent", this.currentDokumen);
      }
      
      if (this.$refs.form.validate()) {
        this.$refs.modal.loading();

        this.G_PostAny("dok/folder", formData)
        .then((res) => {
          if (!res.status) {
            this.getTable();
            this.$snotify.success("Berhasil");
            this.$refs.modal.close();
          } else {
            this.$snotify.warning("Terjadi Kesalahan");
          }
        })
        .finally(() => {
          this.$refs.modal.loadingEnd();
        });

      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    addDokumen(folderid){
      if (this.$refs.formDatadoc) {
        this.$refs.formDatadoc.reset();
        this.$refs.formDatadoc.resetValidation();
      }
      this.setId = null;
      this.getData = [];

      if (folderid == 'root') {
        this.dokumenId = null;
       
      }else{
        this.dokumenId = folderid;
      }
      
      this.$refs.modaldoc.open("Tambah Dokumen", 1000);
    },
    editDokumen(dokumen){
      if (this.$refs.formDatadoc) {
        this.$refs.formDatadoc.reset();
        this.$refs.formDatadoc.resetValidation();
      }

      this.setId = dokumen;
      this.getData = [];
      this.dokumenId = dokumen;
      this.$refs.modaldoc.open("Edit Dokumen", 1000);

      this.G_GetAny("dok/"+dokumen)
      .then((res) => {
        console.log(res);
        this.getData = res.data;
        this.$nextTick(() => {
          this.$refs.registerId.setValue(res.data.registerIds);
          this.$refs.tags.setValue(res.data.tags);

        })
      })
      .finally(() => {
       
      });

    },
    downloadDokumen(dokumenId,downloadToken){
      // this.G_GetAny("dok/"+dokumenId+"/download")
      // .then((res) => {
      //   if (!res.status) {
      //     this.$snotify.success("Berhasil Download");
      //   } else {
      //     this.$snotify.warning("Terjadi Kesalahan");
      //   }
      // })
      // .finally(() => {
       
      // });

      

      window.open(process.env.VUE_APP_URL_API+"/dok/"+dokumenId+"/download?downloadToken="+downloadToken, "_blank");
    },
    sizeFilename(size){
      if (size > 0) {
        if(((size/1024)/1024) > 1){
          return ((size/1024)/1024).toFixed(2)+' Mb';
        }  
        else if ((size/1024) > 1) {
          return (size/1024).toFixed(2)+' Kb';
        }else{
          return size+' b';
        }
      }else{
        return ''
      }
      
        

    },
    storeDokumen() {
      let myForm = document.getElementById("formDatadoc");
      let formData = new FormData(myForm);
      if(this.currentDokumen){
        formData.append('dokumenIdParent',this.currentDokumen)
      }
     

      if (this.$refs.form.validate()) {
        this.$refs.modaldoc.loading();

        if (this.setId) {
          formData.append('dokumenId',this.setId)

          this.G_PutAny("dok", formData)
          .then((res) => {
            if (!res.status) {
              this.getTable();
              this.$snotify.success("Berhasil");
              this.$refs.modaldoc.close();
            } else {
              this.$snotify.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modaldoc.loadingEnd();
          });
        }else{
          this.G_PostAnyCustom("dok", formData)
          .then((res) => {
            if (!res.status) {
              this.getTable();
              this.$snotify.success("Berhasil");
              this.$refs.modaldoc.close();
            } else {
              this.$snotify.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modaldoc.loadingEnd();
          });
        }

        

      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    async removeDokumen(id) {
      let judul = "Hapus Dokumen";
      let subJudul = "Yakin Akan Hapus Dokumen Ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();
        this.G_DeleteAny("dok/" + id)
          .then((res) => {
            console.log(res);
            if (res) {
              this.$refs.confirm.close();
              this.getTable();
              this.$snotify.success("Berhasil Hapus Dokumen");
            } else {
              this.$snotify.warning(res.message);
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },
    detailTag(tag){
      this.$refs.modaltag.open("Dokumen Terkait "+tag, '80%');
      this.loadingTbDetail = true;
     
        this.G_GetAny("dok/search?tag="+tag).then((res) => {
          this.loadingTbDetail = false;
          this.tbDataTags = res.data;
        });
   
    }
   
  },
};
